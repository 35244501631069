import { useEffect, useState } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'

import { allowLiveStreamAd } from 'environment'

import { useAppSelector } from 'redux/hooks'

import { ClientFlags } from 'redux/active/actions/types'

import Icon from 'components/elements/Icon'

import styles from './streamAd.module.scss'

type Props = {}

export const isLiveStreamActive = (clientFlags: ClientFlags) => {
  const asteriIsLive = allowLiveStreamAd()

  return asteriIsLive

  // Turning off twitch integration for EDHREC completely
  // Now only asteriIsLive will enable this component

  // const twitchStreamMap = clientFlags.twitch
  // const activeStream = twitchStreamMap
  //   ? Object.keys(twitchStreamMap).find(key => twitchStreamMap[key as keyof typeof twitchStreamMap])
  //   : null

  // return !!(asteriIsLive || activeStream)
}

const StreamAd: React.FC<Props> = () => {
  const [isClient, setIsClient] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [closeVisible, setCloseVisible] = useState(false)
  const [asteriLiveStreamTimeBlock] = useState(allowLiveStreamAd())

  const twitchStreamMap = useAppSelector(state => state.active.clientFlags.twitch)
  const activeStream = twitchStreamMap
    ? Object.keys(twitchStreamMap).find(key => twitchStreamMap[key as keyof typeof twitchStreamMap])
    : null

  const router = useRouter()

  const unique_id = new Date().getTime()

  useEffect(() => {
    setIsClient(true)
    setTimeout(() => setCloseVisible(true), 3000)
  }, [])

  if (!asteriLiveStreamTimeBlock && !activeStream) return null
  if (!isClient || !isOpen) return null

  // Explictly disable autoplay for edhrecast
  // Will need to update this at a later point if we add more channels
  const autoPlay = twitchStreamMap['edhrecast'] ? 'false' : 'true'

  return (
    <div key={router.asPath} className={`${styles.container} ${activeStream && styles.internalStream}`}>
      <div className={styles.innerContainer}>
        {asteriLiveStreamTimeBlock && (
          <>
            <div
              // @ts-ignore
              asterilivestream_height={window.innerWidth <= 600 ? 90 : 250}
              asterilivestream_profile="106_86"
              asterilivestream_width={window.innerWidth <= 600 ? 160 : 300}
            />
            <Script
              id={`${unique_id}`}
              async
              src={`https://asteriresearch.com/livestream-latest.min.js?v=${unique_id}`}
              type="text/javascript"
            />
          </>
        )}

        {!asteriLiveStreamTimeBlock && activeStream && (
          <iframe
            src={`https://player.twitch.tv/?channel=${activeStream}&parent=topdekt.com&parent=archidekt.com&muted=true&autoplay=${autoPlay}`}
            height={window.innerWidth <= 600 ? 90 : 169}
            width={window.innerWidth <= 600 ? 160 : 300}
            allowFullScreen></iframe>
        )}

        {closeVisible && (
          <button className={styles.closeButton} onClick={() => setIsOpen(false)}>
            <Icon name="close" />
          </button>
        )}
      </div>
    </div>
  )
}

export default StreamAd
